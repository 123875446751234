import { LocationStrategy } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbCarouselConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ProfileModalComponent } from './modules/header/topbar/profile-modal/profile-modal.component';
import { AuthService } from './services/auth.service';
import { PerformanceService } from './services/performance.service';
import { UserService } from './services/user.service';
import { Constants } from './shared/constants';
import { ImagePath } from './shared/image-path';

import { environment } from 'src/environments/environment';
import { FirmwareForceUpgradeDevices, FirmwareForceUpgradeList, SuperAdminUpgradeFirmwareSettings } from './models/firmware-force-upgrade';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GatewayAuthRequest } from './models/gateway-auth-request';
import { SuperAdminSettingsService } from './services/super-admin-settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirmWareService } from './services/firmware.service';
import { GatewayCredentialsPattern, GatewayStatusCode, ProgressbarType, XanbusStatusCode, XanbusStatusMsg } from './shared/firmware-enum';
import { FirmwareDeviceDetails, FirmwareUpgradeRequest } from './models/firmware-upgrade-request';
declare let gtag: Function; // Declare ga as a function

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {


  currentUserFullName: string;
  currentUserFName: string;
  currentUserLName: string;
  profileDataRecieved: Subscription;
  eventRefreshSubscriptionSubscription: Subscription;
  userTypeSubscription: Subscription;
  routeSubscription: Subscription;
  profileImageChangeEvent: Subscription;


  // firmware check on login
  checkNewFirmwareVersionSub: Subscription
  getUserPreferenceSub: Subscription
  siteWithNewFirmwareAvailable: string = null
  flashMessage: string = "";
  upgradeMessage : String ="";
  firmwareModalRef: any;
  firmwareForceUpgradeModalRef: any;
  selectedSite: string = "";
  getDeviceFirmwareSub: Subscription;
  setUserPreferenceSub: Subscription;
  @ViewChild("firmwareSplashContent", { static: true }) firmwareSplashContent;
  @ViewChild("firmwareForceUpgradeContent", { static: true }) firmwareForceUpgradeContent;
  userPreference: boolean = false;
  retryPowerGuageAPI: boolean = false;
  modalPopupHeader: string = "";
  latestFirmwareUpgradeDetails: any;
  deviceTypeFirmwareUPgrade: string;
  firmwareUpgradeDevicesList: Array<FirmwareForceUpgradeList>;
  deviceLoginForm: FormGroup;
  passwordInputType: boolean;
  isDeviceCredentialsSubmitted: boolean;
  credentialsRequest: GatewayAuthRequest;
  authenticationResponse: any;
  authenticationMessage: string;
  hasAlert: boolean;
  isValidCredntials: boolean;
  private siteFirmwareUpgradeStatus: Subscription;
  progressBarMaxValue: number;
  progressBartype: string;
  xanbusRequestIndex: number;
  xanbusRequestsSet: any[];
  statusTimerId: any;
  siteFirmwareStatusTimerId: any;
  isSuccessAlert: boolean;
  firmwareSettings: SuperAdminUpgradeFirmwareSettings;
  lastUpgradeVersion: string;
  lastBuildNo: string;
  lastInstanceId: string;
  SiteName: string;
  selectedSiteDevicesData: any;
  isUpgradeCompletionContainer: boolean;
  modalSettings: any;


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private modalService: NgbModal,
    config: NgbCarouselConfig,
    private performanceService: PerformanceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: LocationStrategy,

    private superAdminSettings: SuperAdminSettingsService,
    private spinner: NgxSpinnerService,
    private authservice: AuthService, private fb: FormBuilder,
    private firmwareservice: FirmWareService
  ) {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
    
    )
    .subscribe((event) => {
      this.updateSubMenuFromOutside();
      this.showTopBar = event.toolbar; // show the toolbar?
      this.showFooter = event.toolbar;
      
    })
    config.interval = 5000;
    config.animation = false;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;

    this.profileImageChangeEvent = this.userService.userNameChangeEmitted$.subscribe(event => {
      this.currentUserFullName = this.authService.getLoggedInUserFirstname() + ' ' + this.authService.getLoggedInUsersLastname();
    });

    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      // set isBackButtonClicked to true.
      // this.someNavigationService.setBackClicked(true);
      const selectedSite = authService.getSelectedSite()

      if (selectedSite == "") {
        // let hasOpenedModal = this.modalService.hasOpenModals();
        // if (!hasOpenedModal) {
        //   this.createSite()
        // }
        this.authService.logout();
        // setTimeout(() => {
        // }, 120);
      } else {
        this.router.navigate(['/dashboard']);
      }

      // this.routeSubscription = this.router.events.subscribe((event) => {
      //   console.log('selectedSite===1======' + router.url);
      //   console.log('selectedSite====2=====' + selectedSite);
      //   if (event instanceof NavigationEnd) {
      //     if (router.url === '/dashboard' && selectedSite == "") {
      //       console.log('selectedSite====3=====' + selectedSite);
      //       this.router.navigate(['/dashboard']);
      //       // setTimeout(() => {
      //       // }, 120);
      //     }
      //   }
      // });
    });

    if (parseInt(this.authService.getTotalRegisteredSites()) > 1) {
      this.isMoreThanSingleSite = true;
    } else {
      this.isMoreThanSingleSite = false;
    }

    setTimeout(() => {
      let cond = this.authService.getTotalRegisteredSites();
      if (cond !== 'error') {
        this.formatNavMenuBasedOnUserType()
      }
    }, 280);


    // setTimeout(() => {
    //   console.log('sdsddsdsds============' + authService.getSelectedSite());
    //   console.log('sdsddsdsds============' + router.url);

    //   if (router.url == "/dashboard" && authService.getSelectedSite() == "" && authService.getSelectedSite() != null && authService.getSelectedSite() != undefined) {
    //     this.createSite()
    //   }
    // }, 100);

    this.eventRefreshSubscriptionSubscription = this.performanceService.siteListChangeEmitted$.subscribe(siteListCount => {
      // This is event based API call
      if (siteListCount > 1) {
        this.getUserType()
      }
    });

    this.eventRefreshSubscriptionSubscription = this.performanceService.changeEmitted$.subscribe(event => {
      // This is event based API call
      if (event === Constants.WIDGET_REFRESH_EVENT_NAME.EVENT_SITE) {
        this.getUserType()
      }
    });

    // this.authService.conextInsightUserType.subscribe(data => {
    //   let userType: string = data;
    //   console.log('Usehjjanged-----------------------------' + userType);
    //   let userTypeRole: string = this.authService.getUserRole();
    // })
  }

  getUserType() {
    if (this.getAlarmSubscriptionStatus) {
      return false;
    } else {
      this.getAlarmSubscriptionStatus = true;
      this.userTypeSubscription = this.authService.getConextInsightUserType(this.authService.getSelectedSite()).subscribe(data => {
        if (data != null) {
          this.getAlarmSubscriptionStatus = false;
          let userType = data.list;
          this.authService.updateConextInsightUserType(userType);
          this.formatNavMenuBasedOnUserType()
        }
      }, err => {
        this.getAlarmSubscriptionStatus = false;
      });
    }
  }

  getAlarmSubscriptionStatus: boolean = false;

  // getUserAlarmsWarnings() {
  //   if (this.getAlarmSubscriptionStatus) {
  //     console.log('***************Sidebar Alarms and Warnings api call is already in progress, please wait for response *******************');
  //     return false;
  //   } else {
  //     this.getAlarmSubscriptionStatus = true;
  //     console.log('***************Sidebar Alarms and Warnings is called at ' + new Date() + ' *******************');
  //     this.getAlarmSubscription = this._eventservice.getUserBasedEvents().subscribe((data) => {
  //       console.log('getAlarmSubscription data --------------------- > ' + JSON.stringify(data));
  //       this.getAlarmSubscriptionStatus = false;
  //     }

  @ViewChild("siteCreatePopUp", { static: true }) siteCreateModal: TemplateRef<any>;
  modalRef: NgbModalRef;
  createSite() {
    setTimeout(() => {
      this.modalRef = this.modalService.open(this.siteCreateModal, { backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title', windowClass: 'siteRegistrationHomeCustomModalClass' });
      // this.modalRef = this.modalService.open(this.siteFilterModalModal, { centered: true, windowClass: 'customisationCustomModalClass' });
      this.modalRef.result.then((result) => {
      }).catch((error) => {
      });
    }, 1000);
  }


  isCI2User: boolean = true
  formatNavMenuBasedOnUserType() {
    const userType = this.authService.getCIUserType()
    const userRole = this.authService.getUserRole()
    if (userType == "ci1") {
      this.isCI2User = false
    } else {
      this.isCI2User = true
      this.doUpdateRoleBasedUI()
    }
  }

  isAllowedByUserRole: boolean = true
  isMoreThanSingleSite: boolean = true
  isSiteAdministrator: boolean = true
  doUpdateRoleBasedUI() {
    const userRole = this.authService.getUserRole()
    if (parseInt(this.authService.getTotalRegisteredSites()) > 1) {
      if (userRole == Constants.INSIGHT_CLOUD_USER_ROLE.BASIC_END_USER) {
        this.isAllowedByUserRole = false;
        this.isSiteAdministrator = false;
        this.isMoreThanSingleSite = true;
      } else {
        this.isAllowedByUserRole = true;
        this.isMoreThanSingleSite = true;
        this.isSiteAdministrator = false;
        if (userRole == Constants.INSIGHT_CLOUD_USER_ROLE.SITE_ADMININSTRATOR) {
          this.isSiteAdministrator = true;
        }
      }
    } else {
      if (userRole == Constants.INSIGHT_CLOUD_USER_ROLE.BASIC_END_USER) {
        this.isSiteAdministrator = false;
        this.isAllowedByUserRole = false;
        this.isMoreThanSingleSite = false;
      } else {
        this.isSiteAdministrator = false;
        this.isAllowedByUserRole = true;
        this.isMoreThanSingleSite = false;
        if (userRole == Constants.INSIGHT_CLOUD_USER_ROLE.SITE_ADMININSTRATOR) {
          this.isSiteAdministrator = true;
        }
      }
    }
  }

  showTopBar: boolean = true;
  showFooter: boolean = false;

  ngOnInit(): void {
    this.firmwareSettings = null;
    this.resetDefaultData();
    if(this.authService.getCIUserType() === 'ci2'){
      this.resetForceFirmwareUpgradeData();
      this.validateUserPreference();
    }else{
      this.authservice.doSetFirmwareUpgradeStatus("noupgrade")
      this.authService.forceFirmwareUpgradeStatus.next("noupgrade")
    }
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)).subscribe(event => {
          // Page routing details shared to google analytics dashboard. 
          if (event instanceof NavigationEnd && environment.productionAnalytics) {
            gtag('event', 'page_view', {
              page_path: event.urlAfterRedirects
            })
          }
        });

    //* logic to check ruter path everytime the component is reinstanciated Each router path keeps his meta data of hide/show toolbar logic 
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )

      .subscribe(event => {
        this.updateSubMenuFromOutside();
        this.showTopBar = event.toolbar; // show the toolbar?
        this.showFooter = event.toolbar
      });

    this.activateTab('Home');
    this.detectScreenSizeForHeightSidebar();

    //* set profile image changed 
    this.profileDataRecieved = this.userService.changeEmitted$.subscribe(event => {
      if (event === Constants.PROFILE_DATA_RECIEVED) {
        let dataObj = this.userService.userDataProfileImage;
        if (dataObj) {
          // this.currentUserFullName = this.authService.getLoggedInUsername();
          this.currentUserFName = dataObj.firstName;
          this.currentUserLName = dataObj.lastName;
          this.currentUserFullName = this.currentUserFName + ' ' + this.currentUserLName;
        }
      }
    });

  }


  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  isMobileView: boolean = false;
  private detectScreenSize() {
    // we will write this logic later
    setTimeout(() => {
      if (window.innerWidth < 965) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    }, 200);
  }

  navbarItems: any = Constants.NAVBAR_ITEMS;
  cancelIcon: string = ImagePath.cancelSvgIcon
  menuArrowIcon: string = ImagePath.menuArrowIcon

  goToSchneiderWebPortalForDataPrivacyAndCookiePolicy() {
    window.open(
      'https://www.se.com/ww/en/about-us/legal/data-privacy.jsp',
      '_blank'
    );
  }

  // This is only for left sidebar
  _opened = false;
  _modeNum: string = 'over'; // available:  ['over', 'push', 'slide']
  _positionNum: string = "'left'"; // available :  ['left', 'right', 'top', 'bottom']; 
  // Ends - This is only for left sidebar

  // This settings for both the sidebars
  _dock: boolean = false;
  _closeOnClickOutside: boolean = false;
  _closeOnClickBackdrop: boolean = true;
  _showBackdrop: boolean = true;
  _animate: boolean = true;
  _trapFocus: boolean = false;
  _autoFocus: boolean = false;
  _keyClose: boolean = true;
  _autoCollapseHeight: number = null;
  _autoCollapseWidth: number = null;
  //Ends- This settings for both the sidebars

  //Right sidebar code 
  display: boolean = false;  //hidden by default: I am setting this value because horizontal scroll was coming by default on main page because
  // of right scrollbar so, added this variable so whenever required we will change this value to display on the ui
  _opened2: boolean = false;
  _showBackdrop2: boolean = false;
  _dock2: boolean = false;
  _positionNum2: string = "right";
  faultType: string;
  //Ends- Right sidebar code 
  faultCounter: boolean = false; //flag for detecting event change if same event is fired twice
  docHeight: number;
  //  loggedIn: boolean;
  isCi1User: boolean = true;
  showMonitorItems: boolean = true;

  // Custom function for closing right sidebar on outside click
  clickOutsideBar() {
    if (this._opened2 == true)
      this._onClosed();
  }
  monitoringClick() {
    this.showMonitorItems = !this.showMonitorItems;
  }
  /*
  *Below are the Left-right sidebar code
  *Component: https://github.com/arkon/ng-sidebar#readme
  */

  /*--These are optional Events for leftsidbar , similarly you can do for right also. In future if we need do any changes 
     on these below functions then you can write code on these below  */
  _toggleLeftSidebar() {
    if (this.isMobileView)
      this._opened = !this._opened;
  }

  _toggleRightSidebar(event) {
    this._opened2 = !this._opened2;
    this.display = true;  //make the right sidebar open
  }
  _openRightSidebar() {
    this._opened2 = true;
    this.display = true;
  }
  _setFault(faultType) {
    this.faultType = faultType + this.faultCounter;
    this.faultCounter = !this.faultCounter; //toggling counter if same event changes twice
  }

  _onOpened(): void {
    this._animate = false;
    this.display = true;  //make the right sidebar open
    console.info('Sidebar opened');
  }

  _onCloseStart(): void {
    this.display = false;  //make the right sidebar open
    console.info('Sidebar closing');
  }

  _onClosed(): void {
    this._opened2 = false;
    this.display = false;
    console.info('Sidebar closed');
  }


  isMainRoute(url) {
    if (url == 'dashboard' || url == 'multisite' || url == 'mydashboard' || url == 'events') {
      this._toggleLeftSidebar()
      this.router.navigate([url], { relativeTo: this.activatedRoute });
    }

  }

  selectedSubmenu = '';
  submenuClick(submenuRoute, tabName, selectedSubmenu, toggleItem) {
    this._toggleLeftSidebar()
    this.router.navigate([submenuRoute], { relativeTo: this.activatedRoute });
    this.activateTab(tabName);
    this.selectedSubmenu = selectedSubmenu;
    this.toggleSubMenuItem(toggleItem);
  }

  //new variables
  toggleusernameItem: boolean = false;
  toggleMultisiteItem: boolean = false;
  togglePerformanceItem: boolean = false;
  toggleReportingItem: boolean = false;
  toggleEventsItem: boolean = false;
  toggleConfigurationItem: boolean = false;

  toggleSubMenuItem(itemName) {
    if (itemName == 'toggleusernameItem') this.toggleusernameItem = !this.toggleusernameItem; else this.toggleusernameItem = false;
    if (itemName == 'toggleMultisiteItem') this.toggleMultisiteItem = !this.toggleMultisiteItem; else this.toggleMultisiteItem = false;
    if (itemName == 'togglePerformanceItem') this.togglePerformanceItem = !this.togglePerformanceItem; else this.togglePerformanceItem = false;
    if (itemName == 'toggleReportingItem') this.toggleReportingItem = !this.toggleReportingItem; else this.toggleReportingItem = false;
    if (itemName == 'toggleEventsItem') this.toggleEventsItem = !this.toggleEventsItem; else this.toggleEventsItem = false;
    if (itemName == 'toggleConfigurationItem') this.toggleConfigurationItem = !this.toggleConfigurationItem; else this.toggleConfigurationItem = false;
    this.formatNavMenuBasedOnUserType()
  }

  activeTab = '';
  activateTab(tabName) {
    this.activeTab = tabName;
  }
  sideBarFooterHeight = 148;
  private detectScreenSizeForHeightSidebar() {
    if (window.innerHeight < 910 && window.innerHeight > 800) {
      this.sideBarFooterHeight = 338;
    } else if (window.innerHeight < 750 && window.innerHeight > 700) {
      this.sideBarFooterHeight = 248;
    } else if (window.innerHeight < 670 && window.innerHeight > 650) {
      this.sideBarFooterHeight = 178;
    }
  }

  openProfileeditModal() {
    this._toggleLeftSidebar()
    if (this.isMobileView) {
      this.router.navigate(['/userprofile']);
    } else {
      const modalRef = this.modalService.open(ProfileModalComponent, { size: 'lg', scrollable: true });
      modalRef.componentInstance.name = 'ProfileEditModal';
    }
    // const modalRef = this.modalService.open(ProfileModalComponent, { size: 'xl', scrollable: true });
    // modalRef.componentInstance.name = 'ProfileEditModal';
  }

  logOut() {
    this._toggleLeftSidebar()

    this.authService.logout();
  }


  updateSubMenuFromOutside() {
    // detect routepath from constants and assign menu name to subMenu
    let routePath = this.router.url;
    if (routePath) {
      for (let i = 0; i < Constants.NAVBAR_ITEMS.length; i++) {
        let menuItems = Constants.NAVBAR_ITEMS[i];
        if (menuItems.routerLink && menuItems.routerLink == routePath) return;
        else {
          if (menuItems.subMenu) {
            for (let j = 0; j < menuItems.subMenu.length; j++) {
              let subMenu = menuItems.subMenu[j];
              // if (subMenu.subMenuName === this.selectedSubmenu) return;
              if (('/' + subMenu.routerLink) == routePath) {
                this.selectedSubmenu = subMenu.subMenuName;
                let strAr = menuItems.itemName;
                strAr = strAr.charAt(0).toUpperCase() + strAr.substring(1).toLowerCase();
                this.activateTab(strAr.replace(/ +/g, ""));
                return;
              }
            }
          }
        }
      }
    }
  }



  // Check new firware version available ? after login

  //Create Device login form
  createDeviceLoginForm = () => {
    this.deviceLoginForm = this.fb.group({
      username: [
        "",
        [
          Validators.required,
          Validators.pattern(GatewayCredentialsPattern.USER_NAME),
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.pattern(GatewayCredentialsPattern.PASSWORD),
        ],
      ],
    });
  };

  private resetForceFirmwareUpgradeData = () => {
    this.firmwareUpgradeDevicesList = [];
    this.passwordInputType = false;
    this.isDeviceCredentialsSubmitted = false;
    this.authenticationResponse = null;
    this.authenticationMessage = "";
    this.hasAlert = false;
    this.isValidCredntials = false;
    this.progressBarMaxValue = 100;
    this.progressBartype = "success";
    this.xanbusRequestIndex = 0;
    this.xanbusRequestsSet = [];
    this.statusTimerId = this.siteFirmwareStatusTimerId = null;
    this.isSuccessAlert = true;
    this.selectedSiteDevicesData = null;
    this.modalSettings = {
      size: "lg",
      centered: true,
      keyboard: false,
      backdrop: "static",
    };
    this.createDeviceLoginForm();
  };


  private resetDefaultData = () => {
    this.superAdminSettings.hasForceUpgrade = false;
    this.lastUpgradeVersion = null;
    this.lastBuildNo = null;
    this.lastInstanceId = null;
    this.SiteName = null;
    this.isUpgradeCompletionContainer = false;
  };
  private showCompletionScreen = () => {
    this.isUpgradeCompletionContainer = true;
    this.modalSettings.size = "sm";
  };

  private setFirmwareForceUpgradeDeviceList = (devicesList: any) => {
    let sites = devicesList;
    for (var site in sites) {
      let devices = sites[site];
      let devicesList = [];
      for (var device in devices) {
        devicesList.push(
          new FirmwareForceUpgradeDevices(
            this.deviceTypeFirmwareUPgrade + "[" + device + "]",
            this.deviceTypeFirmwareUPgrade,
            device,
            devices[device][0],
            devices[device][1],
            devices[device][2],
            0,
            "Not started",
            "success"
          )
        );
      }
      this.firmwareUpgradeDevicesList.push(
        new FirmwareForceUpgradeList(
          site,
          devicesList,
          false,
          false,
          false,
          false
        )
      );
      this.firmwareUpgradeDevicesList[0].enableUpgrade = true;
      this.firmwareUpgradeDevicesList[0].enableUpgradeSection = true;
    }
  };

  private generateFirmwareUpgradeRequest = (siteDetails) => {
    let devicesToUpgrade = siteDetails.devices;
    let xanbusRequestsSet = [];
    devicesToUpgrade.forEach((device, index) => {
      let xanbusDetail = [];
      xanbusDetail.push(
        new FirmwareDeviceDetails(
          device.type,
          device.id,
          this.latestFirmwareUpgradeDetails.uri,
          this.latestFirmwareUpgradeDetails.fileName
        )
      );
      xanbusRequestsSet.push(
        new FirmwareUpgradeRequest(
          siteDetails.id,
          xanbusDetail,
          this.deviceLoginForm.value.username,
          this.deviceLoginForm.value.password,
          this.authenticationResponse.sessionId,
          this.authenticationResponse.dbosAccessToken,
          this.authenticationResponse.ipaddr
        )
      );
    });
    this.xanbusRequestsSet = xanbusRequestsSet;
  };

  //Show alert message on successful or failure of credentials authentication.
  showAlert(message, alertType) {
    this.authenticationMessage = message;
    if (alertType === "SUCCESS") {
      this.isSuccessAlert = true;
      this.hasAlert = true;
      setTimeout(() => {
        this.hasAlert = false;
        this.authenticationMessage = "";
        //this.modifySettings('deviceLoginSelector');
      }, 10000);
    } else if (alertType === "FAILURE") {
      this.isSuccessAlert = false;
      this.hasAlert = true;
      setTimeout(() => {
        this.hasAlert = false;
        this.authenticationMessage = "";
      }, 10000);
    }
  }

  upgradeXanbusDevices = () => {
    let reqIndex = this.xanbusRequestIndex;
    let xanbusRquest = this.xanbusRequestsSet[reqIndex];
    if (xanbusRquest != null || xanbusRquest != undefined) {
      this.SiteName = this.selectedSiteDevicesData.id;
      this.lastInstanceId = this.selectedSiteDevicesData.devices[reqIndex].id;
      this.lastUpgradeVersion = this.selectedSiteDevicesData.devices[
        reqIndex
      ].version;
      this.lastBuildNo = this.selectedSiteDevicesData.devices[reqIndex].build;
      this.firmwareservice.upgradeXanbusFirmware(xanbusRquest).subscribe(
        (response) => {
          if (response === true) {
            this.getUpgradeStatus();
          }
        },
        (error) => {
          this.showAlert("Something went wrong, Try later", "FAILURE");
        }
      );
    } else if (xanbusRquest == null || xanbusRquest == undefined) {
      this.spinner.show("reloadLoader");
      setTimeout(() => this.refreshFirmwareList(), 30000);
    }
  };

  getUpgradeStatus = () => {
    let reqIndex = this.xanbusRequestIndex;
    let xanbusRquest = this.xanbusRequestsSet[reqIndex];
    if (xanbusRquest != null || xanbusRquest != undefined) {
      this.firmwareservice.pullFirmwareUpgradeStatus(xanbusRquest).subscribe(
        (response) => {
          if (response != null || response != undefined) {
            let siteDevices = this.firmwareUpgradeDevicesList[0].devices;
            let deviceIndex = this.getDeviceItemIndex(
              siteDevices,
              response.instanceId
            );
            this.firmwareUpgradeDevicesList[0].devices[
              deviceIndex
            ].progressPrecent = response.percent;
            this.firmwareUpgradeDevicesList[0].devices[
              deviceIndex
            ].progressStatus = this.getXanbusStatusMessage(response.status);
            this.firmwareUpgradeDevicesList[0].devices[
              deviceIndex
            ].progressType = ProgressbarType.SUCCESS;
            if (response.status === XanbusStatusCode.FAILURE) {
              clearTimeout(this.statusTimerId);
              this.firmwareUpgradeDevicesList[0].devices[
                deviceIndex
              ].progressPrecent = 100;
              this.firmwareUpgradeDevicesList[0].devices[
                deviceIndex
              ].progressStatus = "Failed!";
              this.firmwareUpgradeDevicesList[0].devices[
                deviceIndex
              ].progressType = ProgressbarType.FAILURE;
              this.xanbusRequestIndex++;
              if (this.xanbusRequestIndex <= this.xanbusRequestsSet.length) {
                this.upgradeXanbusDevices();
              } else {
                this.spinner.show("reloadLoader");
                setTimeout(() => this.refreshFirmwareList(), 30000);
              }
            } else if (
              response.status === XanbusStatusCode.SUCCESS ||
              response.status === XanbusStatusCode.IDLE
            ) {
              clearTimeout(this.statusTimerId);
              this.firmwareUpgradeDevicesList[0].devices[
                deviceIndex
              ].progressType = ProgressbarType.SUCCESS;
              this.xanbusRequestIndex++;
              if (this.xanbusRequestIndex <= this.xanbusRequestsSet.length) {
                this.upgradeXanbusDevices();
              } else {
                this.spinner.show("reloadLoader");
                setTimeout(() => this.refreshFirmwareList(), 30000);
              }
            } else if (response.status === XanbusStatusCode.PROGRESS) {
              this.setUpStatusUpdateCall();
            }
          }
        },
        (error) => {
          this.showAlert(
            "Something went wrong!, we are getting you back their",
            "FAILURE"
          );
          this.setUpStatusUpdateCall();
        }
      );
    }
  };

  //Verify for firmware upgrade
  verifyUpgradeProgress() {
    this.spinner.show("verifyLoader");
    if (this.authenticationResponse != null) {
      let siteFirmwareRequest = new FirmwareUpgradeRequest(
        this.selectedSiteDevicesData.id,
        null,
        this.deviceLoginForm.value.username,
        this.deviceLoginForm.value.password,
        this.authenticationResponse.sessionId,
        this.authenticationResponse.dbosAccessToken,
        this.authenticationResponse.ipaddr
      );
      this.siteFirmwareUpgradeStatus = this.firmwareservice
        .getSiteFirmwareUpgradeStatus(siteFirmwareRequest)
        .subscribe((data: any) => {
          if (
            (data.scbUpgradeStatus === GatewayStatusCode.REQUESTED ||
              data.scbUpgradeStatus === GatewayStatusCode.VERIFY ||
              data.scbUpgradeStatus === GatewayStatusCode.DOWNLOAD ||
              data.scbUpgradeStatus === GatewayStatusCode.INIT) &&
            data.xanbusUpgradeStatus === XanbusStatusCode.PROGRESS
          ) {
            this.firmwareUpgradeDevicesList[
              this.getItemIndex(this.selectedSiteDevicesData)
            ].isUpgradeInProgress = true;
            this.spinner.hide("verifyLoader");
            this.setUpSiteFirmwareUpgradeStatusCall();
          } else if (
            (data.scbUpgradeStatus === GatewayStatusCode.INIT ||
              data.scbUpgradeStatus === GatewayStatusCode.DONE) &&
            (data.xanbusUpgradeStatus === XanbusStatusCode.IDLE ||
              data.xanbusUpgradeStatus === XanbusStatusCode.FAILURE ||
              data.xanbusUpgradeStatus === XanbusStatusCode.SUCCESS)
          ) {
            let isInProgress = this.firmwareUpgradeDevicesList[
              this.getItemIndex(this.selectedSiteDevicesData)
            ].isUpgradeInProgress;
            if (isInProgress) {
              this.spinner.show("reloadLoader");
              setTimeout(() => this.refreshFirmwareList(), 30000);
            } else {
              this.siteFirmwareStatusTimerId != null
                ? clearTimeout(this.siteFirmwareStatusTimerId)
                : "";
              this.firmwareUpgradeDevicesList[
                this.getItemIndex(this.selectedSiteDevicesData)
              ].isUpgradeInProgress = false;
              this.spinner.hide("verifyLoader");
              this.generateFirmwareUpgradeRequest(this.selectedSiteDevicesData);
              this.xanbusRequestIndex = 0;
              this.firmwareUpgradeDevicesList[
                this.getItemIndex(this.selectedSiteDevicesData)
              ].isUpgradeStarted = !this.selectedSiteDevicesData
                .isUpgradeStarted;
              this.upgradeXanbusDevices();
            }
          } else if (
            data.xanbusUpgradeStatus === "" &&
            data.scbUpgradeStatus === ""
          ) {
            clearTimeout(this.statusTimerId);
            this.firmwareUpgradeDevicesList[
              this.getItemIndex(this.selectedSiteDevicesData)
            ].isUpgradeInProgress = false;
            this.spinner.hide("verifyLoader");
            this.showAlert(
              "Device not ready. Either device isn't responding or device is shutdown. Kindly retry agin.",
              "FAILURE"
            );
          } else if (
            data.xanbusUpgradeStatus === GatewayStatusCode.UNAUTHORIZED &&
            data.scbUpgradeStatus === XanbusStatusCode.UNAUTHORIZED
          ) {
            clearTimeout(this.statusTimerId);
            this.isDeviceCredentialsSubmitted = false;
            this.isValidCredntials = false;
            this.firmwareUpgradeDevicesList[
              this.getItemIndex(this.selectedSiteDevicesData)
            ].isUpgradeInProgress = false;
            this.firmwareUpgradeDevicesList[
              this.getItemIndex(this.selectedSiteDevicesData)
            ].isUpgradeStarted = false;
            this.authenticationResponse = null;
            this.createDeviceLoginForm();
            this.spinner.hide("verifyLoader");
            this.showAlert(
              "Device session is expeired, kindly login to proceed",
              "FAILURE"
            );
          }
        });
    } else {
    }
  }

  setUpStatusUpdateCall = () => {
    this.statusTimerId = setTimeout(() => {
      this.getUpgradeStatus();
    }, 20000);
  };

  setUpSiteFirmwareUpgradeStatusCall = () => {
    this.siteFirmwareStatusTimerId = setTimeout(() => {
      this.verifyUpgradeProgress();
    }, 20000);
  };

  //Get Xanbus Status message
  getXanbusStatusMessage = (status: string): string => {
    switch (status) {
      case XanbusStatusCode.SUCCESS: {
        return XanbusStatusMsg.SUCCESS;
      }
      case XanbusStatusCode.FAILURE: {
        return XanbusStatusMsg.FAILURE;
      }
      case XanbusStatusCode.IDLE: {
        return XanbusStatusMsg.IDLE;
      }
      case XanbusStatusCode.PROGRESS: {
        return XanbusStatusMsg.UPGRADING;
      }
      default: {
        return "";
      }
    }
  };

  getDeviceFirmware = () => {
    if (this.firmwareSettings != null) {
      this.getDeviceFirmwareSub = this.firmwareservice
        .getDeviceFirmware(this.firmwareSettings)
        .subscribe(
          (data: any) => {
            if (data != null) {
              this.deviceTypeFirmwareUPgrade = data.deviceType;
              this.latestFirmwareUpgradeDetails = data.blobFileDetails;
              if (Object.keys(data.devicesList).length > 0) {
                this.isUpgradeCompletionContainer = false;
                this.spinner.hide("reloadLoader");
                this.resetForceFirmwareUpgradeData();
                this.setFirmwareForceUpgradeDeviceList(data.devicesList);
                this.firmwareForceUpgradeModalRef == null
                  ? this.openFirmwareForceUpgradeScreen()
                  : "";
              } else {
                this.superAdminSettings.hasForceUpgrade = false;
                this.spinner.hide("reloadLoader");
                this.showCompletionScreen();
              }
            } else {
              this.resetDefaultData();
            }
          },
          (error) => {
          }
        );
    } else {
      this.getFirmwareSettings();
    }
  };

  getFirmwareSettings = () => {
    this.superAdminSettings
      .retriveForceUpgradeSettings()
      .subscribe((data: any) => {
        if (data != null && data.isForceUpgrade) {
          this.superAdminSettings.hasForceUpgrade = data.isForceUpgrade;
          this.firmwareSettings = new SuperAdminUpgradeFirmwareSettings(
            data.deviceType,
            data.isLatesVersion,
            data.customUpgradeVersion,
            this.lastUpgradeVersion,
            this.lastBuildNo,
            this.lastInstanceId,
            this.SiteName
          );
          this.getDeviceFirmware();
        } else {
          this.superAdminSettings.hasForceUpgrade = false;
        }
      });
  };

  togglePanel(item) {
    this.firmwareUpgradeDevicesList[
      this.getItemIndex(item)
    ].enableUpgradeSection = !item.enableUpgradeSection;
  }

  private getItemIndex = (item) => {
    var indx = this.firmwareUpgradeDevicesList.findIndex(
      (x) => x.id === item.id
    );
    return indx;
  };

  private getDeviceItemIndex = (devices, id) => {
    var indx = devices.findIndex((x) => x.id === id);
    return indx;
  };

  private refreshFirmwareList = () => {
    this.getFirmwareSettings();
  };

  openFirmwareForceUpgradeScreen = () => {
    this.modalPopupHeader = "Firmware Upgrade";
    this.firmwareForceUpgradeModalRef = this.modalService.open(
      this.firmwareForceUpgradeContent,
      {windowClass: 'forceFirmwareUpgradeCustomModalClass'}
    );
    this.firmwareForceUpgradeModalRef.result
      .then((result) => {
      })
      .catch((error) => {
      });
  };

  openFirmwareSplashScreen = () => {
    let hasOpenedModal = this.modalService.hasOpenModals();
    if (!hasOpenedModal) {
      this.firmwareModalRef = this.modalService.open(this.firmwareSplashContent, {
        size: "lg",
        backdrop: "static",
        centered: true,
        keyboard: false,
      });
      this.firmwareModalRef.result
        .then((result) => {
        })
        .catch((error) => {
        });
    } else {
      this.modalService.dismissAll();
      this.firmwareModalRef = this.modalService.open(this.firmwareSplashContent, {
        size: "lg",
        backdrop: "static",
        centered: true,
        keyboard: false,
      });
      this.firmwareModalRef.result
        .then((result) => {
        })
        .catch((error) => {
        });
    }
  };

  closeFirmwareSplashScreen = (hasNivagation) => {
    this.firmwareModalRef.close();
    if (hasNivagation) {
      this.authservice.setSelectedSite(this.siteWithNewFirmwareAvailable)
      if (this.router.url == "/configuration/firmware") {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([`/configuration/firmware`]).then(() => {
            this.authservice.doSetFirmwareUpgradeStatus("routeandupgrade")
            this.authService.forceFirmwareUpgradeStatus.next("routeandupgrade")
          })
        })
      } else {
        this.router.navigate(["configuration/firmware"]);
        this.authservice.doSetFirmwareUpgradeStatus("routeandupgrade")
        this.authService.forceFirmwareUpgradeStatus.next("routeandupgrade")
      }


    } else {
      this.authservice.doSetFirmwareUpgradeStatus("noupgrade")
      this.authService.forceFirmwareUpgradeStatus.next("noupgrade")
    }
  };

  CloseFirmwareForceUpgradeContent() {
    this.firmwareForceUpgradeModalRef.close();
    this.superAdminSettings.hasForceUpgrade = false;
  }

  setSplashVisibility(event) {
    this.userPreference = event.target.checked;
    this.setUserPreferenceSub = this.firmwareservice
      .updateUserPreference(!this.userPreference)
      .subscribe(
        (data: any) => {
          this.unsubscribeUserPreference();
        },
        (error) => {
        }
      );
  }

  validateUserPreference = () => {
    this.spinner.show("firmwareCheckSpinner");
    // console.log("thishsjhjds-------   " + JSON.stringify(this.router))
    // this.activatedRoute.queryParams.subscribe(params => {
    //   console.log(params);
    //   this.context = params.context;
    //   console.log("upgrade----" + this.context)
    // }
    // );
    
    setTimeout(() => {
      if(this.router.url.includes('/login')){
      }else{
        if (this.authService.getLoggedInUsername()) {
        if(this.authservice.getCIUserType() === "ci2"){
        //Commenting to stop firmware check
          // this.checkFirmwareVersionForSplash();
          //Adding to not allow disable
          this.authservice.doSetFirmwareUpgradeStatus("noupgrade")
          this.authService.forceFirmwareUpgradeStatus.next("noupgrade")
        }
        
        // this.getFirmwareSettings()
      }
      }
    }, 1500);
  };

  checkFirmwareVersionForSplash = () => {
    if(this.authservice.getCIUserType() === "ci2"){
        
    this.checkNewFirmwareVersionSub = this.firmwareservice.getFirmwareDetailData(this.authService.getSelectedSite()).subscribe(
      (data: any) => {
        let userSiteData = data;
        if (
          userSiteData.length > 0
        ) {
          let hasNewFirmware = false;
          this.authservice.doSetFirmwareUpgradeStatus("noupgrade")
          this.authService.forceFirmwareUpgradeStatus.next("noupgrade")
          this.siteWithNewFirmwareAvailable = null
          userSiteData.forEach((item) => {
            const curVersion = item.curVersion.replace(/^\D+/g, '');
            if (curVersion < 1.18 && item.deviceName == "SCB" && item.connectivity == "Online") {
              hasNewFirmware = true;
            }
            this.siteWithNewFirmwareAvailable = this.authService.getSelectedSite()
          });
          if (hasNewFirmware) {
            this.authservice.doSetFirmwareUpgradeStatus("upgrade")
            this.authService.forceFirmwareUpgradeStatus.next("upgrade")
            this.flashMessage =
            "New firmware upgrade available for your site: ";
            this.upgradeMessage = "Please upgrade to the latest firmware to enjoy continued high quality services."
            this.openFirmwareSplashScreen();
            this.unsubscribeFirmwareCheck();
          }
        } else {
          this.siteWithNewFirmwareAvailable = null
        }
      },
      (error) => {
        this.authservice.doSetFirmwareUpgradeStatus("noupgrade")
        this.authService.forceFirmwareUpgradeStatus.next("noupgrade")
      }
    );
    }
  };

  unsubscribeFirmwareCheck = () => {
    if (this.checkNewFirmwareVersionSub) {
      this.checkNewFirmwareVersionSub.unsubscribe();
    }
  };

  unsubscribeUserPreference = () => {
    if (this.setUserPreferenceSub) {
      this.setUserPreferenceSub.unsubscribe();
    }
  };

  /* Firmware splash screen - END */

  ngOnDestroy(): void {
    if (this.userTypeSubscription) this.userTypeSubscription.unsubscribe()
    if (this.profileImageChangeEvent) this.profileImageChangeEvent.unsubscribe()
  }
}
