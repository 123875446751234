import { Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  faBars,
  faUserCircle,
  faCaretDown,
  faSmileBeam,
  faInfoCircle,
  faUser,
  faCog
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PerformanceService } from 'src/app/services/performance.service';
import { UserService } from 'src/app/services/user.service';
import { Constants } from 'src/app/shared/constants';
import { ImagePath } from 'src/app/shared/image-path';
import { SiteListService } from '../../my-dashboard/dashboard/site-list/site-list.service';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {

  eventRefreshSubscriptionSubscription: Subscription
  performanceServiceOnChangeSubscription: Subscription
  isCI1UserType: boolean = false
  context: string = null
  
  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private authService: AuthService,
    private siteListService: SiteListService,
    private router: Router,
    private performanceService: PerformanceService,
    private activatedRoute: ActivatedRoute
  ) {
    this.eventRefreshSubscriptionSubscription = this.performanceService.siteListChangeEmitted$.subscribe(siteListCount => {
      setTimeout(() => {
        if (authService.getCIUserType() == Constants.INSIGHT_CLOUD_USER_TYPE.CI1) {
          this.isCI1UserType = true
        } else {
          this.isCI1UserType = false
        }
      }, 1000);
    });

    setTimeout(() => {

      if (router.url == "/dashboard" && authService.getSelectedSite() == "" && authService.getSelectedSite() != null && authService.getSelectedSite() != undefined) {
        this.createSite()
      }
    }, 1000);

    this.performanceServiceOnChangeSubscription = this.performanceService.siteDeletedEmitted$.subscribe(event => {
      if (event === 'siteDeleted') {
        setTimeout(() => {
          if ((router.url == "/configuration/siteAndDevices" || router.url == "/configuration/deviceSettings" || router.url == "/configuration/firmware" || router.url == "/configuration/emailNotifications" || router.url == "/configuration/userRights" || router.url == "/configuration/financialSettings") && authService.getSelectedSite() == "" && authService.getSelectedSite() != null && authService.getSelectedSite() != undefined) {
            let hasOpenedModal = this.modalService.hasOpenModals();
            if (hasOpenedModal) {
              this.modalService.dismissAll();
            }
            this.createSite()
          }
        }, 2500);
      }
    });
  }


  // Handle click event to open schneider's solar portal on clicking on logo after loging in application
  goToSchneiderWebPortal() {
    window.open("https://solar.schneider-electric.com/", "_blank");
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {

    let hasOpenedModal = this.modalService.hasOpenModals();
    if (hasOpenedModal) {
      this.modalService.dismissAll();
    }
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  isMobileView: boolean = false;
  private detectScreenSize() {
    // we will write this logic later
    setTimeout(() => {
      if (window.innerWidth < 991) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    }, 78);
  }

  @ViewChild("siteCreatePopUp", { static: true }) siteCreateModal: TemplateRef<any>;
  modalRef: NgbModalRef;
  createSite() {
    setTimeout(() => {
      this.modalRef = this.modalService.open(this.siteCreateModal, { backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title', windowClass: 'siteRegistrationHomeCustomModalClass' });
      // this.modalRef = this.modalService.open(this.siteFilterModalModal, { centered: true, windowClass: 'customisationCustomModalClass' });
      this.modalRef.result.then((result) => {
      }).catch((error) => {
      });
    }, 1000);
  }


  faBars = faBars;
  faUserCircle = faUserCircle;
  faSmile = faSmileBeam;
  faCaretDown = faCaretDown;
  faInfoCircle = faInfoCircle;
  faUser = faUser;
  faCog = faCog;
  profileImageSource: any = '';
  profileImageChangeEvent: Subscription;
  localSiteListCountTopBar: number = 0;

  createSiteIcon = ImagePath.createSiteIcon;
  notificationIcon = ImagePath.notificationIcon;
  multiSiteIcon = ImagePath.multiSiteIcon;
  configurationIcon = ImagePath.configurationIcon;
  devicePerformanceAnalysisIcon = ImagePath.devicePerformanceAnalysisIcon;
  reportingIcon = ImagePath.reportingIcon;

  firstName: any = null

  ngOnInit(): void {
    this.context = null
    setTimeout(() => {
      this.activatedRoute.queryParams
        .subscribe(params => {
          this.context = this.authService.doGetFirmwareUpgradeStatus()
        }
        );
    }, 1000);

    this.authService.forceFirmwareUpgradeStatus.subscribe(data => {
      this.context = data
    })
    //* Set user profile pic on instantiation
    let selectedSite = this.authService.getSelectedSite();
    if (
      selectedSite == null ||
      selectedSite == '' ||
      selectedSite == undefined
    ) {
    } else {
      this.fetchProfileImagedata();
    }

    //* set profile image changed 
    this.profileImageChangeEvent = this.userService.changeEmitted$.subscribe(event => {
      if (event === Constants.PROFILE_IMAGE_CHANGE_EVENT) {
        this.profileImageSource = this.userService.profileImage;
      }
    });
    this.profileImageChangeEvent = this.userService.userNameChangeEmitted$.subscribe(event => {
      this.fetchUserProfile()
    });

    this.siteListService.siteListCount.subscribe((data) => {
      if (data && data.length) this.localSiteListCountTopBar = data.length;
    })
    this.fetchUserProfile()
  }

  @Output() sideBarEvent = new EventEmitter<string>();

  callParentForLeftSidebarChange() {
    this.sideBarEvent.next('left');
  }
  openProfileeditModal() {
    const modalRef = this.modalService.open(ProfileModalComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.name = 'ProfileEditModal';
  }

  openFeedBackModal() {
    const modalRef = this.modalService.open(FeedbackModalComponent, { windowClass: 'feedback-css', size: 'lg' });
    modalRef.componentInstance.name = 'SendFeedBackModal';
  }

  fetchProfileImagedata() {
    let selectedSite = this.authService.getSelectedSite();
    this.userService.getProfileImageData(selectedSite).subscribe((data) => {

      if (data) {
        const { profilePic, ...profileData } = data;
        if (profileData) {
          this.userService.userDataProfileImage = profileData;
          this.userService.emitChange(Constants.PROFILE_DATA_RECIEVED);

        }
        if (profilePic) {
          this.profileImageSource = `data:image/jpeg;base64,${profilePic}`;
          //set data for global use case
          this.userService.profileImage = `data:image/jpeg;base64,${profilePic}`;
          this.userService.userDataProfileImage = profileData;
        }
      }

    });
  }
  // Logout User & Redirect To Login Page

  logOut() {
    this.authService.logout();
  }

  fetchUserProfile() {
    let selectedSite = this.authService.getSelectedSite();

    if (!!selectedSite) {
      this.authService.getUserProfile(selectedSite).subscribe((data) => {
        if (data && data.msg) {
          let userProfileData = JSON.parse(data.msg);
          let userName = userProfileData.firstName.split(" ")
          this.firstName = userName[0]
        }
      });
    }
  }
  submenuClick(submenuRoute) {
    this.router.navigate([submenuRoute], { relativeTo: this.activatedRoute });
  }
}
