/**
 * @author sesa 523962 - Suchrita Jain
 * @email suchrita.jain@non.se.com
 * @create date 2019-06-21 10:59:35
 * @modify date 2019-06-21 10:59:35
 * @desc This service is responsible for getting input from perfomrance component and generating data
 *  based on input paramenters and events
 */
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { SimpleCrypt } from 'ngx-simple-crypt';
import { APPDATA } from '../shared/data-values/application.properties';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../shared/api_urls';

@Injectable({
  providedIn: 'root'
})
export class
  PerformanceService {

  private deviceName: string = '';
  private deviceType: string = 'inv1';
  private data: any = [];
  private emitChangeSource = new Subject<any>();
  private emitSiteListChangeSource = new Subject<any>();
  private emitTriggerCreateSitePopUpChangeSource = new Subject<any>();
  private emitSiteDeletedSource = new Subject<any>();
  private emitSiteDuplicatedSource = new Subject<any>();
  private emitSiteCreateEventSource = new Subject<any>();
  private control: any = [];
  private systemInfo: any = [];
  private currentState: any = [];
  private faultType: number;
  private fault: string = '';
  day: string;
  month: string;
  year: string;
  enableDay: boolean;
  enableMonth: boolean;
  enableYear: boolean;
  enableYearOnly: boolean;
  intervalType: string;
  fromDate: string;
  toDate: string;
  seletedDeviceParams: any;
  dateValue: string = 'DAY';//By default
  deviceId: number = 0;
  deviceTypeInDropDown: string = '';
  isCustomDate: any = false;
  clickedOn: any = '';

  private simpleCrypt = new SimpleCrypt();
  private appData = APPDATA;

  private dateViewState = new BehaviorSubject(false);
  currentDateViewState = this.dateViewState.asObservable();
  _powermixTabId: number = null;
  _siteOverviewActiveTabId: string = null;

  constructor(private localStorage: LocalStorageService, private http: HttpClient) {
    this.control = {
      'Inverter mode': 'yes',
      'Battery type': 'value',
      'Nominal battery voltage': 'value'
    };
    this.systemInfo = {
      'FGA number': 'value',
      'Firmware version': 'value'
    };
    this.currentState = {
      'State': 'ON',
      'Running since': '340s'
    };
    this.faultType = 2;
    this.fault = '1 WARNING';
  }


  private selectedDeviceData: any = [];
  get selectedDevice(): any {
    return this.selectedDeviceData;
  }
  set selectedDevice(val: any) {
    this.selectedDeviceData = val;
  }

  private selectedDeviceCategoryData: any = [];
  get selectedDeviceCategory(): any {
    return this.selectedDeviceCategoryData;
  }
  set selectedDeviceCategory(val: any) {
    this.selectedDeviceCategoryData = val;
  }



  updateDevice(deviceName) {
    this.deviceName = deviceName;
  }

  // updateSelectedDevice(data) {
  //   this.seletedDeviceParams = data;
  // }

  // getSelectedDevice() {
  //   return this.seletedDeviceParams
  // }

  updateDeviceType(deviceType) {
    this.deviceType = deviceType;
  }
  updateDeviceId(deviceId) {
    this.deviceId = deviceId;
  }
  updateDeviceTypeInDropDown(deviceTypeInDropDown) {
    this.deviceTypeInDropDown = deviceTypeInDropDown;
  }
  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  updateEnergyGaugeDate(dateValue) {
    this.dateValue = dateValue;
  }
  // New Date Picker Code
  updateDateNew(day, month, year, enableDay, enableMonth, enableYear, enableYearOnly, fromd, to, isCustomDate: any = false, clickedOnFromDropDown: any = '') {
    this.day = day;
    this.month = month;
    this.year = year;
    this.enableDay = enableDay;
    this.enableMonth = enableMonth;
    this.enableYear = enableYear;
    this.enableYearOnly = enableYearOnly;
    this.isCustomDate = isCustomDate;
    this.clickedOn = clickedOnFromDropDown;

    this.fromDate = fromd
    this.toDate = to
    if (enableDay) {
      if (this.fromDate === this.toDate) {
        this.intervalType = 'HOUR';
        this.fromDate = fromd
        this.toDate = to
      } else {
        this.intervalType = 'DAY';
        this.fromDate = fromd
        this.toDate = to
      }
    } else if (enableMonth) {
      if (this.fromDate === this.toDate) {
        this.intervalType = 'DAY';
        this.fromDate = fromd + '-01';
        let z = to.split('-')
        this.toDate = to + '-' + this.getDaysInMonth(z[1], z[0]);
      } else {
        this.intervalType = 'MONTH';
        this.fromDate = fromd + '-01';
        let m = to.split('-')
        this.toDate = to + '-' + this.getDaysInMonth(m[1], m[0]);
      }

    } else if (enableYear) {
      if (this.fromDate === this.toDate) {
        this.intervalType = 'MONTH';
        this.fromDate = fromd + '-01-01';
        this.toDate = to + '-12-31';
      } else {
        this.intervalType = 'YEAR';
        this.fromDate = fromd + '-01-01';
        this.toDate = to + '-12-31';
      }
    } else if (enableYearOnly) {
      this.intervalType = 'YEAR';
      this.fromDate = '2020-07-01';
      this.toDate = this.year + '-05-31';
    }
  }
  // New Date Picker Code

  updateDate(day, month, year, enableDay, enableMonth, enableYear, enableYearOnly, isCustomDate: any = false, clickedOnFromDropDown: any = '') {
    this.day = day;
    this.month = month;
    this.year = year;
    this.enableDay = enableDay;
    this.enableMonth = enableMonth;
    this.enableYear = enableYear;
    this.enableYearOnly = enableYearOnly;
    this.isCustomDate = isCustomDate;
    this.clickedOn = clickedOnFromDropDown;

    //Default set to today
    this.fromDate = this.year + '-' + this.month + '-' + this.day;
    this.toDate = this.year + '-' + this.month + '-' + this.day;

    if (enableDay) {
      this.intervalType = 'HOUR';
      this.fromDate = this.year + '-' + this.month + '-' + this.day;
      this.toDate = this.year + '-' + this.month + '-' + this.day;

    } else if (enableMonth) {
      this.intervalType = 'DAY';
      this.fromDate = this.year + '-' + this.month + '-01';
      this.toDate = this.year + '-' + this.month + '-' + this.getDaysInMonth(this.month, this.year);

    } else if (enableYear) {
      this.intervalType = 'MONTH';
      this.fromDate = this.year + '-01-01';
      this.toDate = this.year + '-12-31';

    } else if (enableYearOnly) {
      this.intervalType = 'YEAR';
      this.fromDate = this.year + '-01-01';
      this.toDate = this.year + '-12-31';
    }
  }

  getData() {
    return this.data = {
      deviceType: this.deviceType,
      deviceName: this.deviceName,
      control: this.control,
      systemInfo: this.systemInfo,
      currentState: this.currentState,
      faultType: this.faultType,
      fault: this.fault,
      day: this.day,
      month: this.month,
      year: this.year,
      enableDay: this.enableDay,
      enableMonth: this.enableMonth,
      enableYear: this.enableYear,
      enableYearOnly: this.enableYearOnly,
      intervalType: this.intervalType,
      fromDate: this.fromDate,
      toDate: this.toDate,
      deviceTypeInDropDown: this.deviceTypeInDropDown,
      deviceId: this.deviceId,
      isCustomDate: this.isCustomDate,
      clickedOn: this.clickedOn
    };
  }


  private powerflowRoutedDevicedetailsFromMyDashboard = new Subject<any>();

  setPowerflowRoutedDeviceFromMyDashboard(data: string) {
    this.powerflowRoutedDevicedetailsFromMyDashboard.next({ seletedDeviceDetailFromMyDashboard: data });
  }

  getPowerflowRoutedDeviceFromMyDashboard(): Observable<any> {
    return this.powerflowRoutedDevicedetailsFromMyDashboard.asObservable();
  }


  changeEmitted$ = this.emitChangeSource.asObservable();
  emitChange(eventName) {
    this.emitChangeSource.next(eventName);
  }

  siteListChangeEmitted$ = this.emitSiteListChangeSource.asObservable();
  emitSiteListChange(eventName) {
    this.emitSiteListChangeSource.next(eventName);
  }

  triggerCreateSitePopUpEmitted$ = this.emitTriggerCreateSitePopUpChangeSource.asObservable();
  triggerCreateSitePopUpChange(data) {
    this.emitTriggerCreateSitePopUpChangeSource.next(data);
  }

  siteDeletedEmitted$ = this.emitSiteDeletedSource.asObservable();
  emitSiteDeletedEvent(eventName) {
    this.emitSiteDeletedSource.next(eventName);
  }

  siteDuplicatedEmitted$ = this.emitSiteDuplicatedSource.asObservable();
  emitSiteDuplicatedEvent(eventName) {
    this.emitSiteDuplicatedSource.next(eventName);
  }

  emitSiteCreateEventSourceEmitted$ = this.emitSiteCreateEventSource.asObservable();
  emitSiteCreateEvent(eventName) {
    this.emitSiteCreateEventSource.next(eventName);
  }

  private emitChangeSourceForSelectedDevice = new Subject<any>();
  changeEmittedForSelectedDevice$ = this.emitChangeSourceForSelectedDevice.asObservable();
  updateSelectedDevice(device) {
    this.emitChangeSourceForSelectedDevice.next(device);
  }

  changeDateViewState(state: boolean) {
    this.dateViewState.next(state)
  }

  setPerformanceTab(tabName: string) {
    let key = this.appData[17].cnxtId;
    let encodedString = this.simpleCrypt.encode(key, tabName);
    this.localStorage.store(key, encodedString);
  }

  getPerformanceTab() {
    let key = this.appData[17].cnxtId;
    let decodedString = this.simpleCrypt.decode(key, this.localStorage.retrieve(key));
    return decodedString;
  }

  get selectedTabId(): number {
    return this._powermixTabId;
  };
  set selectedTabId(name: number) {
    this._powermixTabId = name;
  };

  get activeTabId(): string {
    return this._siteOverviewActiveTabId;
  };
  set activeTabId(name: string) {
    this._siteOverviewActiveTabId = name;
  };

  public getAlarmsAndWarningsAnalysisData(queryObj: any): Observable<any> {
    return this.http.post<any>(API_URLS.ALARMS_ANALYSIS_API, queryObj);
  }

  public getBenefitsSavingsEvolution(queryObj: any): Observable<any> {
    return this.http.post<any>(API_URLS.SAVINGS_API, queryObj)
  }

  public getBenefitsEnvironmentalAndFinancialSavings(queryObj: any): Observable<any> {
    return this.http.post<any>(API_URLS.ENV_FINANCIAL_API, queryObj)
  }

  public getBenefitsPaybackTrack(queryObj: any): Observable<any> {
    return this.http.post<any>(API_URLS.PAYBACK_TRACK_API, queryObj)
  }
}
