import { Component, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs/internal/Subscription';
import { MapsService } from 'src/app/services/maps.service';
import { AuthService } from 'src/app/services/auth.service';
// import { AlertService } from 'src/app/services/alert.service';
import { CreateMapService } from 'src/app/services/create-map.service';
import { GeoLocationService } from 'src/app/services/geo-location.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { MulitSiteService } from 'src/app/services/mulit-site.service';
import { SelectedSiteService } from 'src/app/services/selected-site.service';
import { SiteService } from 'src/app/services/site.service';
import { ErrorDialogComponent } from '../dialogueboxes/error-dialog/error-dialog.component';
import { SitePushPop } from 'src/app/shared/data-values/multi-site-enum';
import { DatePipe, formatDate, Location } from '@angular/common';
import { WarnDialogComponent } from '../dialogueboxes/warn-dialog/warn-dialog.component';
import { SiteDevicesList } from 'src/app/models/site-devices-list';
import { SuccessDialogComponent } from '../dialogueboxes/success-dialog.component';
import { DeviceList } from 'src/app/models/device-list';
import { SelectedDevicetype } from 'src/app/models/selected-device-type';
import { DeviceConfigService } from 'src/app/services/device-config.service';
// import { $ } from 'protractor';
import { PerformanceService } from 'src/app/services/performance.service';
import { Observable, Subject, of, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, filter, tap, switchMap, delay } from 'rxjs/operators';
// import { AzureMapSiteRegistrationComponent } from '../performance/azure-map-site-registration/azure-map-site-registration.component';
import { InfoDialogComponent } from '../dialogueboxes/info-dialog/info-dialog.component';


interface MyObj {
  successMsg: string;
  selectedSite: string;
}

interface ImageDetail {
  name: string;
  type: string;
  lastModifiedDate: string;
  tysizepe: string;
}

class deviclist {
  gateWayName: string;
  gateWayId: string;
  gateWaySerialNo: string;
}


@Component({
  selector: 'site-registration',
  styleUrls: ['./site-registration.component.scss'],
  templateUrl: './site-registration.component.html'
})

export class SiteRegistrationComponent implements OnInit, OnDestroy {
  @Input() mode: 'site' | 'configuration' = 'site';
  @Input() tab: 'settings' | 'devices' | 'documents' = 'settings';

  loggedInUsername: string;
  mapSiteLocation: string;
  isSiteAvailable: boolean = true;
  isSiteAvailableMsg: string;
  firstImageFile: string = null;
  secondImageFile: string = null;
  thirdImageFile: string = null;
  GatewayDeviceList = [];
  duplicateSiteDataNew: any;
  siteDevicesList: SiteDevicesList[] = [];
  siteElement: SiteDevicesList;
  getDeviceListInfo: Subscription;
  deviceList: DeviceList[] = [];
  hasURLParam: boolean;
  routedDeviceCategory: string;
  routedDeviceElement: DeviceList;
  routedDeviceFriendlyName: string;
  routedDeviceId: string;
  routedDeviceName: string;
  routedDeviceType: string;
  userFriendlyMsg: string;
  requireRetry: boolean;
  enableRetry: boolean;
  selectedSiteDeviceItem: DeviceList;
  deviceId: string;
  selectedDeviceType: string;
  tabs = [];
  currentActiveTab: 'settings' | 'devices' | 'documents';
  getuserType: any;
  deleteSiteService: Subscription
  closeResult = '';
  submitted: boolean;
  siteSubmit: boolean = false;
  siteList: any;
  validate: boolean = false;
  even: string;
  today: Date;
  gatewayTooTipMessage: string = `Locate The MAC Address From Product Rating Label (Example: 11:AA:11:33:44:0A/11-22-33-AA-0A-0A)`;
  gatewaySerialIdTooTipMessage: string = `Locate The Serial Number From Product Rating Label`;
  siteNameToolTipMessage: string = `Site Name Must Be Unique`;

  constructor(private _mapsService: MapsService, private modalService: NgbModal, private spinner: NgxSpinnerService, private createMapservice: CreateMapService,
    private fb: FormBuilder, public zone: NgZone, private _location: Location, private geoLocationService: GeoLocationService,
    private authservice: AuthService, private siteService: SiteService, private route: Router,
    private selectedSiteService: SelectedSiteService, private configService: DeviceConfigService, private multisiteService: MulitSiteService,
    private _googleAnalyticsService: GoogleAnalyticsService, private mapsService: MapsService, private performanceService: PerformanceService) {
    this.createMapservice.sites = [];
    // this.getDefaultCurrentUserLocation()

    this.mapsService.siteRegistrationChangeMarkerEmitted$.subscribe(data => {
      this.siteLocationForm.patchValue({
        useMyCurrentLocation: false,
        street: data.street,
        zip: data.zip,
        city: data.city,
        country: data.country,
        longitude: data.lon,
        latitude: data.lat
      });
      this.siteLocationForm.markAsDirty()
    });
  }

  // getDefaultCurrentUserLocation() {
  //   this.geoLocationService.getPosition().subscribe(
  //     (pos: any) => {
  //       var arr = [];
  //       arr.push(+(pos.coords.longitude));
  //       arr.push(+(pos.coords.latitude));
  //       //this.myLatArray = arr;
  //       // lang = +(pos.coords.longitude);
  //       // lat = +(pos.coords.latitude);
  //       this.siteLocationForm.patchValue({
  //         longitude: pos.coords.longitude,
  //         latitude: pos.coords.latitude,
  //       })
  //       this._mapsService.updateCoordinateEvent({ "lat": pos.coords.latitude, "lon": pos.coords.longitude });
  //       this.getReverseAddressByLatLon({ "lat": pos.coords.latitude, "lon": pos.coords.longitude })
  //     })
  // }

  regexValidationExp = /^\d{1,7}([\.](\d{1,7})?)?$/;
  regexLat = /^(?=.)-?((8[0-9]?)|([0-7]?[0-9])|9([0]))?(?:\.[0-9]{1,20})?$/;
  regexLong = /^(?=.)-?((0?[8-9][0-9])|180|([0-1]?[0-7]?[0-9]))?(?:\.[0-9]{1,20})?$/;

  siteForm: any = this.fb.group({
    userId: [''],
    siteName: ['', Validators.compose([Validators.required, Validators.pattern('^[ a-zA-Z0-9-+_]+$'), Validators.maxLength(100)])],
    dateCommissioned: [],
    // siteLocation: this.fb.group({
    //   useMyCurrentLocation: [false],
    //   street: ['', Validators.compose([Validators.required, Validators.pattern('^[ a-zA-Z0-9-/#&()\'\.,]+$'), Validators.minLength(5), Validators.maxLength(200)])],
    //   zip: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9-a-zA-Z]+$'), Validators.minLength(3), Validators.maxLength(10)])],
    //   city: ['', Validators.compose([Validators.required, Validators.pattern('^[ a-zA-Z-\'\.,]+$'), Validators.minLength(2), Validators.maxLength(50)])],
    //   country: ['', Validators.compose([Validators.required, Validators.pattern('^[ a-zA-Z-()\'\.,\"]+$'), Validators.minLength(2), Validators.maxLength(50)])],
    // }),
    // longitude: ['', Validators.compose([Validators.required, Validators.pattern(this.regexLong), Validators.minLength(1), Validators.maxLength(100)])],
    // latitude: ['', Validators.compose([Validators.required, Validators.pattern(this.regexLat), Validators.minLength(1), Validators.maxLength(100)])],
  });

  siteLocationForm: any = this.fb.group({
    useMyCurrentLocation: [false],
    street: [''],
    zip: [''],
    // zip: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9-a-zA-Z]+$'), Validators.minLength(3), Validators.maxLength(10)])],
    city: [''],
    country: [''],
    longitude: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(100)])],
    latitude: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(100)])],
  });


  addDeviceForm: any = this.fb.group({
    userId: [''],
    gateWayId: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9:-]+$'), Validators.minLength(10), Validators.maxLength(50)])],
    gateWaySerialNo: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(1), Validators.maxLength(20)])],

  });

  get siteFormValidation() {
    return this.siteForm.controls;
  }

  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  loadingClients = false;
  searchFailed = false;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  typeaheadClient: string;
  searchClient = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    const hideLoading$ = new Observable(() => () => (this.loadingClients = false));
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$, hideLoading$).pipe(
      tap(() => this.loadingClients = true),
      switchMap(
        term => this.getClientsAsObservable(term)
      ),
      delay(1000),
      tap(() => this.loadingClients = false)
    );
  }

  formatter = (x: any) => {
    // this.geoLocationService.getCurrentLocationAzureSearchPostalCode(x.countryCode).subscribe(data => {
    if (x.zip != "") {
      this.siteLocationForm.patchValue({
        street: x.street,
        zip: x.zip,
        city: x.city,
        country: x.country,
        longitude: x.lon.toFixed(2),
        latitude: x.lat.toFixed(2),
      })
      this._mapsService.updateCoordinateEventInSIteRegistration({ "lat": x.lat, "lon": x.lon });
      this.siteLocationForm.markAsDirty()
    } else {
      this.getReverseAddressByLatLon({ "lat": x.lat, "lon": x.lon })
      setTimeout(() => {
        this._mapsService.updateCoordinateEventInSIteRegistration({ "lat": x.lat, "lon": x.lon });
      }, 750);
    }
    // console.log('searcsedhQuery----------------' + JSON.stringify({ "lat": x.lat, "lon": x.lon }));
    // })
  }

  getReverseAddressByLatLon = (data) => {
    // console.log('searcsedhQuery----------------' + JSON.stringify({ "lat": x.lat, "lon": x.lon }));
    this.geoLocationService.getCurrentLocationAzureLatLonDetails(data.lat, data.lon).subscribe(x => {
      this.siteLocationForm.patchValue({
        street: x.street,
        zip: x.zip,
        city: x.city,
        country: x.country,
        longitude: data.lon.toFixed(2),
        latitude: data.lat.toFixed(2),
      })
      this.siteLocationForm.markAsDirty()
      // this._mapsService.updateCoordinateEvent({ "lat": x.lat, "lon": x.lon });
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let size = {
      "width": Math.floor(event.target.innerWidth / 3),
      "height": Math.floor(event.target.innerHeight / 2)
    }
    // this.mapsService.resizeAzureMapAction(size)
  }

  getClientsAsObservable(term: any): Observable<any> {
    return this.geoLocationService.getCurrentLocationAzureSearch(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })
    );
  }

  ngOnInit() {
    this.loggedInUsername = this.authservice.getLoggedInUsername();
    this.detectAddNewUserFormChanges()
    this.today = new Date();
    this.siteForm.patchValue({
      gatewayType: 'Insight Facility'
    })
  }


  detectAddNewUserFormChanges() {
    this.siteForm.get('siteName').valueChanges.subscribe(changes => {
      if (changes != null && changes != undefined) {
        if (changes.charAt(0) == " " || changes.slice(-1) == " ") {
          this.isSiteAvailable = true;
          this.isSiteAvailableMsg = 'first & last character cannot be space';
        } else if (changes == "script") {
          this.isSiteAvailable = true;
          this.isSiteAvailableMsg = 'script is invalid site name, try other';
        } else {
          this.isSiteAvailable = false;
          this.isSiteAvailableMsg = ''
          this.isSiteExist(changes)
        }
      }
      // if (changes == "script") {
      //   this.isSiteAvailable = true;
      //   this.isSiteAvailableMsg = 'script is invalid site name, try other';
      // } else {
      //   this.isSiteAvailable = false;
      //   this.isSiteAvailableMsg = ''
      //   this.isSiteExist(changes)
      // }
    })
  }

  isSiteExist(even) {
    let siteNameCheck = even;
    this.siteService.isSiteExists(siteNameCheck).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isSiteAvailableMsg = '';
        this.isSiteAvailable = false;
      } else if (data['status'] == 'Failure') {
        this.isSiteAvailableMsg = 'Site name already in use. Please choose different one';
        this.isSiteAvailable = true;
      }
    });
  }

  // getResults() {
  //   this.siteService.searchData('ab', 4).subscribe(data => {
  //     console.log('Rejgjvdsfsd---------------' + JSON.stringify(data));
  //   });
  // }

  addNewSiteSubscription: Subscription;
  addSiteResponse: any;
  siteCreation: boolean = false;


  clearForm() {
    this.isSiteAvailable = false;
    this.siteForm.reset();
    this.siteLocationForm.reset();
    this.clearDeviceForm();
    this.typeaheadClient = ''
    this.mapsService.siteRegistrationRemoveMarker(null)
  }


  clearDeviceForm() {

    this.addDeviceForm.reset();
    this.validate = false;
  }

  createNewSite() {
    let deviceDetails: any = []
    this.siteSubmit = true;
    if (this.siteSubmit) this.spinner.show("sitecreateSpinner");
    let siteObj = this.siteForm.value;
    let siteLocationDetails = this.siteLocationForm.value;
    let siteObjectValidation = siteObj;
    let installedLocation = {
      "useMyCurrentLocation": false,
      "street": siteLocationDetails.street,
      "city": siteLocationDetails.city,
      "country": siteLocationDetails.country,
      "zip": siteLocationDetails.zip
    }
    if (siteObj.siteName.includes("<") || siteObj.siteName.includes(">")) {
      this.authservice.setModalMessage('script, <, and > is invalid in site name, please try some valid data');
      this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
      return false;
    }
    const siteNm = siteObj.siteName
    if (siteNm.toLowerCase() == "script") {
      this.authservice.setModalMessage('script is an invalid site name, please try some valid data');
      this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
      return false;
    }
    if (siteObj.siteName == null || siteObj.siteName == undefined || siteObj.siteName.toLowerCase() == "null" || siteObj.siteName.toUpperCase() == "null") {
      this.authservice.setModalMessage('Invalid site name, please try some valid data');
      this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
      return false;
    }
    if (!/^[a-zA-Z0-9 &_+-]+$/.test(siteObj.siteName)) {
      this.authservice.setModalMessage('Invalid  Site name, please try some valid data');
      this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
      return false;
    }
    if (this.isSiteAvailable) return false;
    // console.log("submit----------------" + JSON.stringify(this.siteForm.value));
    if (this.siteForm.dirty && this.siteForm.valid) {
      // console.log("submit----------------" + JSON.stringify(this.siteForm.value));
    }
    this.siteForm.patchValue({
      userId: this.authservice.getLoggedInUsername(),
    });

    siteObj.dateCommissioned = this.today;


    siteObj.firstImageFile = this.firstImageFile;
    siteObj.secondImageFile = this.secondImageFile;
    siteObj.thirdImageFile = this.thirdImageFile;
    const finalSiteName = siteObj.siteName
    siteObj.siteName = finalSiteName.trim()
    delete siteObj['userId']
    siteObj.siteLocation = installedLocation
    siteObj.latitude = siteLocationDetails.latitude
    siteObj.longitude = siteLocationDetails.longitude
    let addGateway = this.addDeviceForm.value;
    let deviceDetail = {
      'gateWayId': addGateway.gateWayId,
      'gateWaySerialNo': addGateway.gateWaySerialNo,
      'siteName': addGateway.siteName
    };
    deviceDetails.push(deviceDetail)
    siteObj.deviceList = deviceDetails
    this.addNewSiteSubscription = this.siteService.createSite(siteObj, null, null, null).subscribe(
      (data: any) => {
        this.spinner.hide("newSiteSpinner");
        if (data['status'] == 'Success') {
          this.multisiteService.publishSitePushPopChange(SitePushPop.NEW);
          this.addSiteResponse = data
          // console.log("data from login ts:----------"+ JSON.parse(this.loggedInUser));
          let _siteData = JSON.stringify(this.addSiteResponse.msg);
          let _siteDataObj = JSON.parse(_siteData);
          let obj: MyObj = JSON.parse(_siteDataObj);
          let siteNameAfterSuccessObj = this.siteForm.value;
          let siteNameAfterSuccess = siteNameAfterSuccessObj.siteName;
          let _successMsg = obj.successMsg;
          let _selected_site = obj.selectedSite;
          this.authservice.setSelectedSite(siteNameAfterSuccess);
          this.authservice.setUserRole("Site administrator");
          // this.authservice.setCreateSiteSuccessMsg(`${_successMsg}`);
          // this.authservice.setModalMessage(`${_successMsg}`)
          //this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });

          // this.addNewDevice();
          this.authservice.updateHeaderAlarms(true);
          this.getCiIUserType(siteNameAfterSuccess)
          this.spinner.hide("configSpinner");
          this.tab = 'devices'
          this.GatewayDeviceList = [addGateway];
          //this.duplicateSiteDataNew.deviceList = [addGateway];
          this.authservice.setSelectedSiteUrn(addGateway.gateWayId);
          this.siteElement = null;
          this.siteDevicesList = [];
          // this._authService.updateConextInsightUserType(data.msg);
          // this.siteDevicesList.splice(-1, 1);
          // if (this.siteDevicesList.length > 1) {
          //   this.siteDevicesList[1].isCollapsable = !this.siteDevicesList[1].isCollapsable;
          // }
          //this.getDeviceListData('devices');
          this.modalService.dismissAll();
          this.authservice.setModalMessage("" + this.authservice.getSelectedSite() + " created successfully. Use Configuration->Settings tab to enter other site details");
          this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
          this.performanceService.emitChange('site');

          this.performanceService.emitSiteCreateEvent('siteCreate')
        } else if (data['status'] == 'Failure') {
          this.siteSubmit = false;
          this.spinner.hide("sitecreateSpinner");

          // this.alertService.success("Error in creating new site");
          this.authservice.setModalMessage("Unable to create site, please try again");
          this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
        }
      },
      error => {
        this.spinner.hide("sitecreateSpinner");
        this.siteSubmit = false;
        this.authservice.setModalMessage("Unable to create new site, please try again");
        this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
        // throw error;
      }
    );
  }

  /**
   * Sends event to google analytics service.
   */
  public sendEvent() {
    //We call the event emmiter function from our service and pass in the details
    this._googleAnalyticsService.eventEmitter('userPage', 'New Site', 'userLabel', 1);

  }

  previousPage() {
    this._location.back();
  }

  open(optionsModal) {
    this.modalService.open(optionsModal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateDevice() {
    this.siteSubmit = true;
    if (this.siteSubmit) this.spinner.show("sitecreateSpinner");
    if (this.addDeviceForm.invalid) {
      return;
    }
    let addGateway = this.addDeviceForm.value;
    let siteName = this.authservice.getSelectedSite();

    this.siteService.validateGatewayDevice(siteName, addGateway).subscribe(data => {
      if (data['status'] == 'Success') {
        this.authservice.updateHeaderAlarms(true);
        this.getCiIUserType(siteName)
        this.spinner.hide("configSpinner");
        this.tab = 'devices'
        this.GatewayDeviceList = [addGateway];
        this.authservice.setSelectedSiteUrn(addGateway.gateWayId);
        this.siteElement = null;
        this.siteDevicesList = [];
        this.authservice.setModalMessage("Device details validated successfully.");
        this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
        this.validate = true;
        setTimeout(() => {
          this.createNewSite();
        }, 500);

        //this.siteSubmit = false;
        //this.spinner.hide("sitetableSpinner");
      } else if (data['status'] == 'Failure') {
        if (data.hasOwnProperty("msg")) {
          this.spinner.hide("sitecreateSpinner");
          this.authservice.setModalMessage(data['msg']);
          this.modalService.open(InfoDialogComponent, { windowClass: 'global-custom-class' });
        } else {
          this.spinner.hide("sitecreateSpinner");
          this.authservice.setModalMessage("Unable to add device details, please try later");
          this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
        }
      }
    }, error => {
      this.spinner.hide("sitecreateSpinner");
      this.authservice.setModalMessage("Unable to add device details, please try later");
      this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
    });
  }

  addNewDevice() {
    if (this.addDeviceForm.invalid) {
      return;
    }
    let addGateway = this.addDeviceForm.value;
    let siteName = this.authservice.getSelectedSite();
    this.spinner.show("configSpinner");
    this.siteService.updateGatewayDevice(siteName, addGateway).subscribe(data => {
      if (data['status'] == 'Success') {
        this.authservice.updateHeaderAlarms(true);
        this.getCiIUserType(siteName)
        this.spinner.hide("configSpinner");
        this.tab = 'devices'
        this.GatewayDeviceList = [addGateway];
        //this.duplicateSiteDataNew.deviceList = [addGateway];
        this.authservice.setSelectedSiteUrn(addGateway.gateWayId);
        this.siteElement = null;
        this.siteDevicesList = [];
        // this._authService.updateConextInsightUserType(data.msg);
        // this.siteDevicesList.splice(-1, 1);
        // if (this.siteDevicesList.length > 1) {
        //   this.siteDevicesList[1].isCollapsable = !this.siteDevicesList[1].isCollapsable;
        // }
        //this.getDeviceListData('devices');
        this.modalService.dismissAll();
        this.authservice.setModalMessage("" + this.authservice.getSelectedSite() + " created successfully. Use Configuration->Settings tab to enter other site details");
        this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
        this.performanceService.emitChange('site');

        this.performanceService.emitSiteCreateEvent('siteCreate')

      } else if (data['status'] == 'Failure') {

        if (data.hasOwnProperty("msg")) {
          this.spinner.hide("sitecreateSpinner");
          this.authservice.setModalMessage(data['msg']);
          this.modalService.open(InfoDialogComponent, { windowClass: 'global-custom-class' });


        } else {
          this.spinner.hide("sitecreateSpinner");
          this.authservice.setModalMessage("Unable to add device details, please try later");
          this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });

        }
      }
    }, error => {
      this.spinner.hide("sitecreateSpinner");
      this.authservice.setModalMessage("Unable to add device details, please try later");
      this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });


    });
    //this.settingTabOnly = 'settings';
  }

  getCiIUserType(siteName) {
    this.getuserType = this.authservice.getConextInsightUserType(siteName).subscribe(data => {
      if (data != null) {
        let userType = data.list
        if (userType == "ci1") {
          this.tabs = [{ "title": "settings", "isActive": true }, { "title": "devices", "isActive": false }];
          this.tab = "settings"
        } else {
          this.tabs = [{ "title": "settings", "isActive": true }, { "title": "devices", "isActive": false }, { "title": "documents", "isActive": false }];
          if (this.currentActiveTab != null || this.currentActiveTab != undefined) {
            this.setSelectedTab(this.currentActiveTab);
          } else {
            this.tab = "settings";
          }
        }
        this.authservice.updateConextInsightUserType(userType);
      }
    });
  }

  private setSelectedTab = (tabName) => {
    this.tabs.forEach(item => {
      (item.title === tabName) ? item.isActive = true : item.isActive = false;
    });
    this.tab = this.currentActiveTab = tabName;
  };


  updatSiteDeviesList(listItem) {
    this.siteDevicesList.push(listItem);
  }


  siteName: any;
  initializeDefaultItems(tabName) {
    this.setSelectedTab(tabName);
    this.siteElement = new SiteDevicesList("DEVICE LIST", [], false);
    this.siteName = this.authservice.getSelectedSite();
    this.siteElement.deviceList.push(new DeviceList(null, 'Site', true, null, null, null));
    this.updatSiteDeviesList(this.siteElement);
    //this.getDeviceListData();// Remove temp data from list
    this.selectedSiteDeviceItem = this.siteDevicesList[0].deviceList[0];
    if (this.siteDevicesList.length > 1) {
      this.siteDevicesList[1].isCollapsable = !this.siteDevicesList[1].isCollapsable;
    }
    // this.onItemSelect(this.selectedSiteDeviceItem, null);
  }

  // Set selected device id to reterive respective config data.
  setSelectedDeviceTypeConfig = (deviceTypeData, deviceCategory, friendlyName) => {
    if (this.mode != 'site') {
      let obj = new SelectedDevicetype(deviceTypeData, deviceCategory, friendlyName);
      this.configService.setSelectedDevicetype(obj);
      this.selectedDeviceType = deviceTypeData;
      //this.deviceCategory = itemCategory;
    }
  }

  onItemSelect(item, itemCategory) {
    // console.log('item', item,' cat ', itemCategory);
    if (this.authservice.getCIUserType() == "ci1") {
      return false;
    }
    this.selectedSiteDeviceItem = item;
    this.selectedSiteDeviceItem.name === 'Site' ? this.mode = 'site' : this.mode = 'configuration';
    this.deviceId = item.name;
    if (this.deviceId != "" || this.deviceId != undefined) {
      this.setSelectedDeviceTypeConfig(this.deviceId, itemCategory, item.friendlyName);
    }
  }


  getDeviceListData(tabName) {
    // this.deviceList = [];
    // // this.siteElement.deviceList.push(new DeviceList(null, 'Site', true, null, null));
    this.siteDevicesList = [];
    this.initializeDefaultItems(tabName);
    this.spinner.show("device-list-Spinner");
    let siteName = this.authservice.getSelectedSite();
    //console.log("value----------" + siteName)
    this.getDeviceListInfo = this.authservice.getDeviceListData(siteName).subscribe(
      (data: any) => {
        this.spinner.hide("device-list-Spinner");
        if (data['status'] == 'Success') {
          let a1 = JSON.stringify(data.response);
          let a2 = JSON.parse(a1);
          a2.forEach(element => {
            this.deviceList = [];
            var isColSec = (this.hasURLParam && this.routedDeviceCategory === element.category) ? false : true;
            this.siteDevicesList.push(new SiteDevicesList(element.category, this.deviceList, isColSec));
            //console.log("hello.........", this.siteDevicesList);
            //console.log("hello.>>>>", element.tsiDevices);
            element.tsiDevices.forEach(element1 => {
              // console.log(element1.instanceId, element1.name, true, element1.status, element1.type);
              this.deviceList.push(new DeviceList((element1.instanceId).toString(), element1.name, true, element1.status, element1.type, element1.friendlyName));
              //console.log("hello instanceId.........>>>>>>>>>>>>>>", element1.instanceId);
              //console.log("hello22.........>>>>>>>>>>>>>>", this.deviceList);
            })
          });
          // this.siteDevicesList.push();
          // this.siteDevicesList.push(new DeviceList(null, 'Site', true, null, null));
          // let deviceListArr = [];
          // deviceListArr.push(new DeviceList(null, 'Site', true, null, null));
          // this.siteDevicesList.unshift(new SiteDevicesList(null, deviceListArr, true));
          if (this.hasURLParam) {
            // console.log("IN list build: ", this.hasURLParam);
            this.siteDevicesList.forEach(devCat => {
              if (devCat.deviceHeader === this.routedDeviceCategory) {
                devCat.deviceList.forEach(item => {
                  if (item.id === this.routedDeviceId) {
                    if (item.status === true) {
                      this.onItemSelect(new DeviceList(this.routedDeviceId, this.routedDeviceName, true, true, this.routedDeviceType, this.routedDeviceFriendlyName), this.routedDeviceCategory,);
                    }
                    else if (item.status === false) {
                      this.mode = 'site';
                      this.authservice.setModalMessage('You cannot change settings while device in offline!');
                      this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
                    }
                  }
                });
              }
            });

          }

          if (this.siteDevicesList.length === 0) {
            this.enableRetry = true;
            this.requireRetry = false;
            this.userFriendlyMsg = "No devices found";
          } else {
            this.enableRetry = false;
          }
          // console.log("duplicateSiteToBeSaved site created:----------" + JSON.stringify(this.siteDevicesList, null, 4));
          // alert("duplicateSiteToBeSaved site created:----------" + JSON.stringify(this.siteDevicesList, null, 4));
        }
      }, error => {
        this.spinner.hide("device-list-Spinner");
        this.enableRetry = true;
        this.requireRetry = false;
        this.userFriendlyMsg = "No devices found";
      }
    );
  }
  selectedSiteToBeDeleted: string;




  ngOnDestroy(): void {
    if (this.getuserType) this.getuserType.unsubscribe()
  }
}